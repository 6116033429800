"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setNotification = void 0;
/*
    Available notification types: 'success', 'error', 'warning', 'info'
    Docs: https://web-standards.pages.bindg.com/moonfish/moonfish-ui/branch/master/?path=/docs/components-notification--page&globals=backgrounds.grid:false
*/
var setNotification = function (message, type, onClose) {
    if (onClose === void 0) { onClose = function () { }; }
    var notification = document.createElement('m-notification');
    notification.setAttribute('type', type);
    notification.innerHTML = message;
    notification.addEventListener('close', onClose);
    document.body.appendChild(notification);
};
exports.setNotification = setNotification;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseDate = exports.env2Postfix = void 0;
var env2Postfix = function () {
    return ({
        sbx: '-s',
        dev: '-d',
        tst: '-q',
        qa: '-q',
        uat: '-u',
        prd: '',
    }[process.env.ENV] || '');
};
exports.env2Postfix = env2Postfix;
var parseDate = function (stringDate) {
    if (!stringDate)
        return '';
    var date = stringDate.substring(0, 10);
    var year = date.substring(0, 4);
    var month = date.substring(5, 7);
    var day = date.substring(8, 10);
    return "".concat(month, "/").concat(day, "/").concat(year);
};
exports.parseDate = parseDate;
